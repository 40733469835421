<template>
    <div v-loading="uploadLoad" class="page-content">
        <Header/>
        <div class="ss-header">
            <img src="./assets/baoming-1.png">
            <div class="title">在线报名</div>
        </div>
        <div class="under-header">
            <div class="sub-block">
                <div class="title-name">
                    大赛报名表上传
                </div>
                <input ref="myFile" style="visibility: hidden;" type="file" @change="uploadFile($event)"/>
                <div class="file-list">
                    <div class="file-block">
                        <img class="file-pic" src="./assets/baoming-3.png">
                        <div class="option-div">
                            <div class="add-file" @click="selectFile(1)">+ {{fileUrl1?'重新选择':'选择文件'}}</div>
                            <div class="move-file" @click="removeFile(1)">
                                <img src="./assets/baoming-4.png">删除
                            </div>
                        </div>
                    </div>
                    <div class="file-block">
                        <img class="file-pic" src="./assets/baoming-3.png">
                        <div class="option-div">
                            <div class="add-file" @click="selectFile(2)">+ {{fileUrl2?'重新选择':'选择文件'}}</div>
                            <div class="move-file" @click="removeFile(2)">
                                <img src="./assets/baoming-4.png">删除
                            </div>
                        </div>
                    </div>
                    <div class="file-block">
                        <img class="file-pic" src="./assets/baoming-3.png">
                        <div class="option-div">
                            <div class="add-file" @click="selectFile(3)">+ {{fileUrl3?'重新选择':'选择文件'}}</div>
                            <div class="move-file" @click="removeFile(3)">
                                <img src="./assets/baoming-4.png">删除
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="submint-btn" @click="submint">
                提交
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            uploadLoad: false,
            selectFileIndex: '',
            fileUrl1:'',
            fileUrl2:'',
            fileUrl3:'',
        }
    },
    methods: {
        removeFile(index){
            this[`fileUrl${index}`] = '';
        },
        selectFile(index){
            console.log(index);
            this.selectFileIndex = index;
            this.$refs.myFile.click();
        },
        async submint(){
            if(this.fileUrl1===''&&this.fileUrl2===''&&this.fileUrl3===''){
                this.$toast('请选择对应文件上传');
                return false;
            }
            const jugres = await this.$http.post('/api/uploadfile/isUploadTable',params);
            if(Number(jugres.is_upload)===1){
                this.$toast('在此之前已上传');
                return false;
            }
            const params = {
            }
            this.fileUrl1 && (params.file1 = this.fileUrl1);
            this.fileUrl2 && (params.file2 = this.fileUrl2);
            this.fileUrl3 && (params.file3 = this.fileUrl3);
            try {
                const res = await this.$http.post('/api/uploadfile/tableUpload',params);
                this.uploadLoad = false;
                if(res){
                    this.$toast('上传成功！');
                    window.location.reload();
                }
            } catch (error) {
                this.uploadLoad = false;
                this.$toast(error.message||'请求失败');
            }
        },
        async uploadFile(e){
            console.log(e,'e');
            const imgFile = e.target.files[0];
            const formData = new FormData();
            formData.append('file', imgFile);
            this.uploadLoad = true;
            try {
                const res = await this.$http.post('/api/common/upload',formData);
                this.uploadLoad = false;
                if(res){
                    this[`fileUrl${this.selectFileIndex}`] = res.url;
                }
                this.$refs.myFile.value = '';
            } catch (error) {
                this.uploadLoad = false;
                this.$toast(error.message||'请求失败');
                this.$refs.myFile.value = '';
            }

        },
    },
}
</script>
<style lang="scss" scoped>
.page-content{
    min-height: 1080px;
    height: 100vh;
    width: 100%;
    .ss-header{
        width: 100%;
        height: 240px;
        position: relative;
        img{
            position: absolute;
            width: 1920px;
            height: 240px;
            top: 0px;
            left: calc(50% - 960px);
        }
        .title{
            font-size: 48px;
            position: absolute;
            z-index: 99;
            padding: 86px 20%;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            text-shadow: 0px 3px 2px rgba(6, 0, 1, 0.35);
        }
    }

    .under-header{
        height: calc(100% - 240px);
        background: url('./assets/baoming-2.png') no-repeat;
        background-size: cover;
        position: relative;
        .sub-block{
            width: 723px;
            height: 473px;
            background: #fff;
            border: 1px dashed #666;
            position: absolute;
            z-index: 55;
            top: calc(50% - 236px);
            left: calc(50% - 361px);
            .title-name{
                text-align: center;
                font-size: 38px;
                color: #000;
                padding: 30px 0;
            }

            .file-list{
                display: flex;
                width: 100%;
                justify-content: space-around;
                .file-block{
                    text-align: center;
                    margin-top: 77px;
                }
                .file-pic{
                    width: 92px;
                    margin-bottom: 40px;
                }

                .option-div{
                    display: flex;
                    width: 192px;
                    height: 41px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    div{
                        cursor: pointer;
                    }
                    .add-file{
                        width: 120px;
                        height: 41px;
                        line-height: 41px;
                        background: #3D5EAA;
                        text-align: center;
                        color: #fff;
                    }
                    .move-file{
                        width: 72px;
                        height: 41px;
                        line-height: 41px;
                        text-align: center;
                        color: #2F3030;
                        border: 1px dashed;
                        box-sizing: border-box;
                        img{
                            vertical-align: middle;
                            width: 12px;
                            margin-right: 3px
                        }
                    }
                }
            }
        }
    }
}

.submint-btn{
    width: 258px;
    height: 45px;
    line-height: 44px;
    background: #3D5EAA;
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    position: absolute;
    border-radius: 30px;
    top: calc(50% + 275px);
    left: calc(50% - 129px);
    cursor: pointer;

}
</style>
